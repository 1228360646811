
const Pokemonlist = [
    {	id:	1,	name:	"abra"	}	,
    {	id:	2,	name:	"absol"	}	,
    {	id:	3,	name:	"aerodactyl"	},
    {	id:	4,	name:	"aipom"	}	,
    {	id:	5,	name:	"anorith"	}	,
    {	id:	6,	name:	"arbok"	}	,
    {	id:	7,	name:	"arcanine"	}	,
    {   id: 8,  name:   "armaldo"   }   ,
    {   id: 9,  name:   "ariados"   }   ,
    {   id: 10, name:   "aron"  }   ,
    {   id: 11, name:   "azumarill" }   ,
    {   id: 12, name:   "bagon" }   ,
    {   id: 13, name:   "baltoy"    }   ,
    {   id: 14, name:   "barboach"  }   ,
    {   id: 15, name:   "bayleef"   }   ,
    {   id: 16, name:   "beedrill"  }   ,
    {   id: 17, name:   "beldum"    }   ,
    {   id: 18, name:   "bellsprout"    }   ,
    {   id: 19, name:   "bidoof"    }   ,
    {   id: 20, name:   "bonsly"    }   ,
    {   id: 21, name:   "breloom"   }   ,
    {   id: 22, name:   "bronzor"   }   ,
    {   id: 23, name:   "buizel"    }   ,
    {   id: 24, name:   "bulbasaur" }   ,
    {   id: 25, name:   "buneary"   }   ,
    {   id: 26, name:   "butterfree"    }   ,
    {   id: 27, name:   "cacnea"    }   ,
    {   id: 28, name:   "cacturne"  }   ,
    {   id: 29, name:   "camerupt"  }   ,
    {   id: 30, name:   "carvanha"  }   ,
    {   id: 31, name:   "cascoon"   }   ,
    {   id: 32, name:   "castform"  }   ,
    {   id: 33, name:   "caterpie"  }   ,
    {   id: 34, name:   "charmander"    }   ,
    {   id: 35, name:   "charmeleon"    }   ,
    {   id: 36, name:   "chikorita" }   ,
    {   id: 37, name:   "chimchar"  }   ,
    {   id: 38, name:   "clefable"  }   ,
    {   id: 39, name:   "clefairy"  }   ,
    {   id: 40, name:   "cleffa"    }   ,
    {   id: 41, name:   "combee"    }   ,
    {   id: 42, name:   "combusken" }   ,
    {   id: 43, name:   "corphish"  }   ,
    {   id: 44, name:   "croagunk"  }   ,
    {   id: 45, name:   "croconaw"  }   ,
    {   id: 46, name:   "cubone"    }   ,
    {   id: 47, name:   "cyndaquil" }   ,
    {   id: 48, name:   "delcatty"  }   ,
    {   id: 49, name:   "diglett"   }   ,
    {   id: 50, name:   "ditto" }   ,
    {   id: 51, name:   "dodrio"    }   ,
    {   id: 52, name:   "dragonair" }   ,
    {   id: 53, name:   "dratini"   }   ,
    {   id: 54, name:   "drowzee"   }   ,
    {   id: 55, name:   "dugtrio"   }   ,
    {   id: 56, name:   "dunsparce" }   ,
    {   id: 57, name:   "dusclops"  }   ,
    {   id: 58, name:   "duskull"   }   ,
    {   id: 59, name:   "eevee" }   ,
    {   id: 60, name:   "ekans" }   ,
    {   id: 61, name:   "electabuzz"    }   ,
    {   id: 62, name:   "electrike" }   ,
    {   id: 63, name:   "entei" }   ,
    {   id: 64, name:   "exeggcute" }   ,
    {   id: 65, name:   "fearow"    }   ,
    {   id: 66, name:   "feebas"    }   ,
    {   id: 67, name:   "finneon"   }   ,
    {   id: 68, name:   "flaaffy"   }   ,
    {   id: 69, name:   "flareon"   }   ,
    {   id: 70, name:   "furret"    }   ,
    {   id: 71, name:   "gastly"    }   ,
    {   id: 72, name:   "gengar"    }   ,
    {   id: 73, name:   "geodude"   }   ,
    {   id: 74, name:   "girafarig" }   ,
    {   id: 75, name:   "gloom" }   ,
    {   id: 76, name:   "golbat"    }   ,
    {   id: 77, name:   "goldeen"   }   ,
    {   id: 78, name:   "golduck"   }   ,
    {   id: 79, name:   "graveler"  }   ,
    {   id: 80, name:   "grimer"    }   ,
    {   id: 81, name:   "grotle"    }   ,
    {   id: 82, name:   "grovyle"   }   ,
    {   id: 83, name:   "growlithe" }   ,
    {   id: 84, name:   "gulpin"    }   ,
    {   id: 85, name:   "gyarados"  }   ,
    {   id: 86, name:   "happiny"   }   ,
    {   id: 87, name:   "hariyama"  }   ,
    {   id: 88, name:   "haunter"   }   ,
    {   id: 89, name:   "hoothoot"  }   ,
    {   id: 90, name:   "hoppip"    }   ,
    {   id: 91, name:   "horsea"    }   ,
    {   id: 92, name:   "houndoom"  }   ,
    {   id: 93, name:   "houndour"  }   ,
    {   id: 94, name:   "hypno" }   ,
    {   id: 95, name:   "igglybuff" }   ,
    {   id: 96, name:   "illumise"  }   ,
    {   id: 97, name:   "ivysaur"   }   ,
    {   id: 98, name:   "jigglypuff"    }   ,
    {   id: 99, name:   "jolteon"   }   ,
    {   id: 100,    name:   "jynx"  }   ,
    {   id: 101,    name:   "kabuto"    }   ,
    {   id: 102,    name:   "kadabra"   }   ,
    {   id: 103,    name:   "kakuna"    }   ,
    {   id: 104,    name:   "kingdra"   }   ,
    {   id: 105,    name:   "kingler"   }   ,
    {   id: 106,    name:   "kirlia"    }   ,
    {   id: 107,    name:   "koffing"   }   ,
    {   id: 108,    name:   "krabby"    }   ,
    {   id: 109,    name:   "kricketot" }   ,
    {   id: 110,    name:   "lairon"    }   ,
    {   id: 111,    name:   "lapras"    }   ,
    {   id: 112,    name:   "larvitar"  }   ,
    {   id: 113,    name:   "ledian"    }   ,
    {   id: 114,    name:   "ledyba"    }   ,
    {   id: 115,    name:   "lickitung" }   ,
    {   id: 116,    name:   "lileep"    }   ,
    {   id: 117,    name:   "linoone"   }   ,
    {   id: 118,    name:   "lopunny"   }   ,
    {   id: 119,    name:   "lotad" }   ,
    {   id: 120,    name:   "luvdisc"   }   ,
    {   id: 121,    name:   "machamp"   }   ,
    {   id: 122,    name:   "machoke"   }   ,
    {   id: 123,    name:   "machop"    }   ,
    {   id: 124,    name:   "magcargo"  }   ,
    {   id: 125,    name:   "magikarp"  }   ,
    {   id: 126,    name:   "magmar"    }   ,
    {   id: 127,    name:   "magnemite" }   ,
    {   id: 128,    name:   "makuhita"  }   ,
    {   id: 129,    name:   "mankey"    }   ,
    {   id: 130,    name:   "mantine"   }   ,
    {   id: 131,    name:   "mareep"    }   ,
    {   id: 132,    name:   "marill"    }   ,
    {   id: 133,    name:   "marowak"   }   ,
    {   id: 134,    name:   "marshtomp" }   ,
    {   id: 135,    name:   "masquerain"    }   ,
    {   id: 136,    name:   "meditite"  }   ,
    {   id: 137,    name:   "meganium"  }   ,
    {   id: 138,    name:   "meowth"    }   ,
    {   id: 139,    name:   "metang"    }   ,
    {   id: 140,    name:   "metapod"   }   ,
    {   id: 141,    name:   "mew"   }   ,
    {   id: 142,    name:   "mewtwo"    }   ,
    {   id: 143,    name:   "miltank"   }   ,
    {   id: 144,    name:   "minun" }   ,
    {   id: 145,    name:   "misdreavus"    }   ,
    {   id: 146,    name:   "monferno"  }   ,
    {   id: 147,    name:   "mudkip"    }   ,
    {   id: 148,    name:   "murkrow"   }   ,
    {   id: 149,    name:   "natu"  }   ,
    {   id: 150,    name:   "nidoran-f" }   ,
    {   id: 151,    name:   "nidoran-m" }   ,
    {   id: 152,    name:   "nidorina"  }   ,
    {   id: 153,    name:   "nidorino"  }   ,
    {   id: 154,    name:   "ninetales" }   ,
    {   id: 155,    name:   "numel" }   ,
    {   id: 156,    name:   "nuzleaf"   }   ,
    {   id: 157,    name:   "octillery" }   ,
    {   id: 158,    name:   "oddish"    }   ,
    {   id: 159,    name:   "omanyte"   }   ,
    {   id: 160,    name:   "onix"  }   ,
    {   id: 161,    name:   "paras" }   ,
    {   id: 162,    name:   "parasect"  }   ,
    {   id: 163,    name:   "pelipper"  }   ,
    {   id: 164,    name:   "persian"   }   ,
    {   id: 165,    name:   "phanpy"    }   ,
    {   id: 166,    name:   "pichu" }   ,
    {   id: 167,    name:   "pidgeot"   }   ,
    {   id: 168,    name:   "pidgeotto" }   ,
    {   id: 169,    name:   "pidgey"    }   ,
    {   id: 170,    name:   "pikachu"   }   ,
    {   id: 171,    name:   "piloswine" }   ,
    {   id: 172,    name:   "pineco"    }   ,
    {   id: 173,    name:   "pinsir"    }   ,
    {   id: 174,    name:   "piplup"    }   ,
    {   id: 175,    name:   "plusle"    }   ,
    {   id: 176,    name:   "poliwag"   }   ,
    {   id: 177,    name:   "poliwhirl" }   ,
    {   id: 178,    name:   "poliwrath" }   ,
    {   id: 179,    name:   "ponyta"    }   ,
    {   id: 180,    name:   "poochyena" }   ,
    {   id: 181,    name:   "porygon"   }   ,
    {   id: 182,    name:   "primeape"  }   ,
    {   id: 183,    name:   "psyduck"   }   ,
    {   id: 184,    name:   "purugly"   }   ,
    // {    id: 185,    name:   "quilfish"  }   ,
    {   id: 186,    name:   "raichu"    }   ,
    {   id: 187,    name:   "ralts" }   ,
    {   id: 188,    name:   "raticate"  }   ,
    {   id: 189,    name:   "rattata"   }   ,
    {   id: 190,    name:   "registeel" }   ,
    {   id: 191,    name:   "relicanth" }   ,
    {   id: 192,    name:   "remoraid"  }   ,
    {   id: 193,    name:   "rhydon"    }   ,
    {   id: 194,    name:   "rhyhorn"   }   ,
    {   id: 195,    name:   "roselia"   }   ,
    {   id: 196,    name:   "sandshrew" }   ,
    {   id: 197,    name:   "sceptile"  }   ,
    // {    id: 198,    name:   "schuckle"  }   ,
    {   id: 199,    name:   "scizor"    }   ,
    {   id: 200,    name:   "scyther"   }   ,
    {   id: 201,    name:   "seadra"    }   ,
    {   id: 202,    name:   "seaking"   }   ,
    {   id: 203,    name:   "sealeo"    }   ,
    {   id: 204,    name:   "seedot"    }   ,
    {   id: 205,    name:   "seel"  }   ,
    {   id: 206,    name:   "sentret"   }   ,
    {   id: 207,    name:   "seviper"   }   ,
    {   id: 208,    name:   "sharpedo"  }   ,
    {   id: 209,    name:   "shellder"  }   ,
    {   id: 210,    name:   "shinx" }   ,
    {   id: 211,    name:   "shroomish" }   ,
    {   id: 212,    name:   "shuppet"   }   ,
    {   id: 213,    name:   "silcoon"   }   ,
    // {    id: 214,    name:   "skarmony"  }   ,
    {   id: 215,    name:   "skiploom"  }   ,
    {   id: 216,    name:   "skitty"    }   ,
    {   id: 217,    name:   "slaking"   }   ,
    {   id: 218,    name:   "slakoth"   }   ,
    {   id: 219,    name:   "slowbro"   }   ,
    {   id: 220,    name:   "slowpoke"  }   ,
    {   id: 221,    name:   "slugma"    }   ,
    {   id: 222,    name:   "smoochum"  }   ,
    // {    id: 223,    name:   "sneazle"   }   ,
    {   id: 224,    name:   "snorlax"   }   ,
    {   id: 225,    name:   "snubbull"  }   ,
    {   id: 226,    name:   "solrock"   }   ,
    {   id: 227,    name:   "spearow"   }   ,
    {   id: 228,    name:   "spheal"    }   ,
    {   id: 229,    name:   "spinarak"  }   ,
    {   id: 230,    name:   "spoink"    }   ,
    {   id: 231,    name:   "squirtle"  }   ,
    {   id: 232,    name:   "starmie"   }   ,
    {   id: 233,    name:   "staryu"    }   ,
    {   id: 234,    name:   "stunky"    }   ,
    {   id: 235,    name:   "sudowoodo" }   ,
    {   id: 236,    name:   "suicune"   }   ,
    {   id: 237,    name:   "sunkern"   }   ,
    {   id: 238,    name:   "surskit"   }   ,
    {   id: 239,    name:   "swablu"    }   ,
    {   id: 240,    name:   "swinub"    }   ,
    {   id: 241,    name:   "taillow"   }   ,
    {   id: 242,    name:   "tangela"   }   ,
    {   id: 243,    name:   "tauros"    }   ,
    {   id: 244,    name:   "teddiursa" }   ,
    {   id: 245,    name:   "tentacool" }   ,
    {   id: 246,    name:   "tentacruel"    }   ,
    {   id: 247,    name:   "torterra"  }   ,
    {   id: 248,    name:   "totodile"  }   ,
    {   id: 249,    name:   "trapinch"  }   ,
    {   id: 250,    name:   "treecko"   }   ,
    {   id: 251,    name:   "turtwig"   }   ,
    {   id: 252,    name:   "tyrogue"   }   ,
    {   id: 253,    name:   "unown" }   ,
    {   id: 254,    name:   "vaporeon"  }   ,
    {   id: 255,    name:   "venomoth"  }   ,
    {   id: 256,    name:   "venonat"   }   ,
    {   id: 257,    name:   "venusaur"  }   ,
    {   id: 258,    name:   "victreebel"    }   ,
    {   id: 259,    name:   "vigoroth"  }   ,
    {   id: 260,    name:   "vileplume" }   ,
    {   id: 261,    name:   "voltorb"   }   ,
    {   id: 262,    name:   "vulpix"    }   ,
    {   id: 263,    name:   "wailmer"   }   ,
    {   id: 264,    name:   "wartortle" }   ,
    {   id: 265,    name:   "weedle"    }   ,
    {   id: 266,    name:   "weepinbell"    }   ,
    {   id: 267,    name:   "weezing"   }   ,
    {   id: 268,    name:   "whiscash"  }   ,
    {   id: 269,    name:   "whismur"   }   ,
    {   id: 270,    name:   "wingull"   }   ,
    {   id: 271,    name:   "wooper"    }   ,
    {   id: 272,    name:   "wurmple"   }   ,
    {   id: 273,    name:   "wynaut"    }   ,
    {   id: 274,    name:   "xatu"  }   ,
    {   id: 275,    name:   "yanma" }   ,
    {   id: 276,    name:   "zigzagoon" }   ,
    {   id: 277,    name:   "zubat" }  
    
];		
export default Pokemonlist;			
